/*
 * Functions for Global Alerts via Bootstrap Component Toasts
 * Template: templates/v5/default/body.html contains {# Global Alerts #} <div> containers
 * Copied and modified from: static_src/js/c2/alerts.js
 */

(function (__module__) {
  'use strict';

  var logger = c3.logging.getLogger(__module__)

  //prevents duplicate messages (having exactly same string content)
  var alreadyAddedMessages = [];

  // Get icon from tags
  function getIconFromTags(tags) {
    switch (tags) {
      case 'error':
      case 'warning':
        return 'fas fa-exclamation-triangle';
      case 'info':
        return 'fas fa-info-circle';
      case 'success':
        return 'fas fa-check';
      default:
        return;
    }
  }

  // Get heading from tags
  function getToastHeading(tags) {
    var validTags = ['error', 'info', 'success', 'warning'];
    if (validTags.indexOf(tags) !== -1) {
      var icon = getIconFromTags(tags) ? '<i class="' + getIconFromTags(tags) + ' me-2"></i>' : '';
      var tagClass = tags == 'error' ? 'danger' : tags;
      return '<strong class="me-auto text-' + tagClass + '">' + icon +
        '<span class="text-capitalize">' + tags + '</span></strong>';
    }
    return;
  }

  // Clear specific alert from alreadyAddedMessages
  function init() {
    document.getElementById('alert-box')?.addEventListener('hidden.bs.toast', () => {
      $('#alert-box').find('div.toast.hide').each(function () {
        var msg = $(this).children('.toast-body').html();
        if (alreadyAddedMessages.indexOf(msg) > -1) {
          alreadyAddedMessages.splice(alreadyAddedMessages.indexOf(msg), 1);
        }
        $(this).remove();
      });
    });
  }

  // Adds alert from Django messages notification
  function addGlobalAlert(msg, tags, dismissable, dismissMilliseconds) {
    var dismiss = "",
      $message;
    var $box = $('#alert-box');

    if (dismissable === true) {
      dismiss = '<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>';
    }

    if (alreadyAddedMessages.indexOf(msg) == -1) {
      logger.debug('Alert msg: ' + msg);
      alreadyAddedMessages.push(msg);

      $message = $('<div role="alert" class="toast align-items-center fade show" aria-live="assertive" aria-atomic="true">' +
        '<div class="toast-header">' + getToastHeading(tags) + dismiss + '</div>' +
        '<div class="toast-body">' + msg + '</div>');

      // Add messages in reverse-chronological order to #alert-box.
      setTimeout(function () {
        $box.prepend($message);
      }, 500);
    }

    if (dismissMilliseconds) {
      setTimeout(clearGlobalAlerts, dismissMilliseconds);
    }
  }

  // Clear all alerts
  function clearGlobalAlerts() {
    var $box = $('#alert-box');

    alreadyAddedMessages = [];
    $box.find('div.toast').each(function () {
      setTimeout(function () {
        $(this).removeClass('show');
      }, 500);
    });
    $box.empty();
  }


  c3[__module__] = {
    addGlobalAlert: addGlobalAlert,
    clearGlobalAlerts: clearGlobalAlerts,
    init: init,
  };
})('alerts');
