/* Global initialization functions
 *
 * This is the entry point for our app and is the first module concatenated into c3.js and
 * c3.min.js. Thus, any global configuration that other c3 modules depend on should be added
 * here at the module level so it can be executed immediately.
 * Copied and modified from: static_src/js/c2/init.js
 */


/*
 * Global c3 object contains settings and will have all modules registered as members
 * once all c3.js code has executed.
 */
window.c3 = {

  /*
   * Global c3.settings
   */
  settings: {

    /*
     * Logging settings.
     * See c3.logging for more details.
     */
    logging: {
      // To enable console logging for a c3 module, add it to this list.  All c3 modules reside in
      // static_src/js/c3/.  Use the exported module name (which sometimes differs from the file
      enabledModules: [],
      // name), e.g.  'server', 'dependentFields'

      // Log level determines the importance of messages that are logged:
      //   debug: show debug, info, warning and error messages.
      //   info: show info, warning, and error messages.
      //   warn: show warnings and errors.
      //   errors are always logged when the module is enabled
      level: 'info'
    },
    urls: {}

  }

};


/*
 * This function is the entry point for our JavaScript.  It must be called from a Django template
 * that has the ability to inject these values.
 */
c3.go = function (
  CBLicenseID,
  mixpanelToken,
  CSRFToken,
  staticURL,
  loginURL,
  logoutURL,
  activityURL,
  detectTimeZone,
  timeoutMin) {

  c3.settings.CBLicenseID = CBLicenseID;
  c3.settings.mixpanelToken = mixpanelToken;
  c3.settings.CSRFToken = CSRFToken;
  c3.settings.urls.static = staticURL;
  c3.settings.urls.login = loginURL;
  c3.settings.urls.logout = logoutURL;
  c3.settings.urls.activity = activityURL;

  // Send a Django encrpytion token with every AJAX request
  $.ajaxPrefilter(function (options, originalOptions, jqXHR) {
    if (!options.crossDomain) {
      // only set token on same-domain requests to avoid leaking it to 3rd parties
      jqXHR.setRequestHeader('X-CSRFToken', CSRFToken);
    }
  });

  $.ajaxSetup({
    // Global error handler that redirects the whole page to the login page if the session is expired:
    // See https://cloudbolt.atlassian.net/browse/DEV-194
    //
    // NOTE: If the .ajax() calls contain another error handler, then BOTH that
    // handler and this global handler will be called.
    //
    // Unfortunately, this does not apply to $.get or $.post calls, which
    // bypass use of jqXHR; we may want to move to using $.ajax everywhere.
    error: function (data) {
      console.log('    Ajax error occurred:', data);

      if (data.status == 401) {
        console.log('    401: session expired. Redirect to log in page.');
        window.location.replace(
          loginURL + "?next=" + encodeURI(window.location.pathname)
        );
      }
    }
  });

  function handleInactivity() {
    window.location.href = logoutURL + '?inactivity-timeout-minutes=' + timeoutMin;
  }

  if (timeoutMin) {
    c3.inactivity.init(timeoutMin, handleInactivity, activityURL);
  }

  if (detectTimeZone) {
    var tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // All modern browsers support the new Internationalization API; ignore otherwise.
    if (tz !== undefined) {
      $.post('/detect_time_zone', {
        tz: tz
      });
    }
  }
};


/*
 * Global initializations that run on document load.
 */
$(function () {
  'use strict';

  c3.accessibility.init();
  c3.alerts.init();
  c3.include.loadAll();
  c3.dialogs.init();
  c3.navbar.init();
  c3.tooltips.init();
  c3.search.init();
  // Call this after other modules have initialized to give them a chance to
  // register event handlers
  c3.commonEventHandlers.bindGlobalKeyHandlers();

  // Customers may completely disable mixpanel by assigning settings.MIXPANEL_TOKEN = ''
  if (c3.settings.mixpanelToken !== '') {
    c3.mixpanel.init();
  }
});
