/* Collapsible panel state persistence. */
/* Copied and modified from: static_src/js/c2/collapsible.js */
(function (c3) {
  'use strict';

  /**
   * Persist state of collapsible panels in localStorage.
   *
   * All [data-toggle=collapse] elements within `container`, a selector or
   * jQuery object, will have their state immediately restored and persisted on
   * change.
   */
  function init(container) {
    var cacheKey = key();
    var $container = $(container);

    // Build a list of IDs for collapsible elements
    var collapsibleIDs = $container.find('[data-bs-toggle="collapse"]').map(function() {
      return $(this).data('test');
    }).get();

    // Restore collapsed state before hooking up shown/hidden event handler
    restoreCollapsedStateOfItems(cacheKey, $container);

    $container.on('shown.bs.collapse hidden.bs.collapse', function () {
      cacheCollapsedStateOfItems(cacheKey, collapsibleIDs);
    });
  }

  // Generate a unique cache key for this page
  function key() {
    return 'c3.collapsible' + window.location.pathname;
  }

  function getClosedCollapsibles(itemIDs) {
    return _.filter(itemIDs, function(item) {

      return !$(`#${item}`).hasClass('show');
    });
  }

  function cacheCollapsedStateOfItems(cacheKey, itemIDs) {
    var closedItems = getClosedCollapsibles(itemIDs);
    localStorage.setItem(cacheKey, JSON.stringify(closedItems));
  }

  function restoreCollapsedStateOfItems(cacheKey) {
    const closedItems = localStorage.getItem(cacheKey);
    if (closedItems === undefined || closedItems === null) {
      return;
    }
    const panelIDs = JSON.parse(closedItems);
    // If initial load, default panels open
    if (panelIDs.length == 0 ) {
      return;
    }
    
    let $accordionContent;
    if (panelIDs && panelIDs.length) {
      _.forEach(panelIDs, function(panelID) {
        $accordionContent = $(`#${panelID}`);
        if ($accordionContent.length) {
          $accordionContent.removeClass("show");
          // Trigger the show event so any global handlers can do their thing
          $accordionContent.trigger("show.bs.collapse");

          // Update the collapse trigger so the indicator is correct
          $('[data-test="' + panelID + '"]').addClass("collapsed");
        } else {
          // Silently ignore panels with persisted state that do not currently
          // exist in the DOM. This can happen when a panel was on a different page
          // of a DataTable, for example.
        }
      });
    }
  }


  // Expose public functions
  c3.collapsible = {
    init: init
  };

})(window.c3);
