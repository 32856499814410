/*
 * Copied from: static_src/js/c2/mixpanel.js
 * Global methods to bind events in our UI to report back to mixpanel
 */

(function (c3, $) {
  'use strict';


  function cleanurl(urlpath) {
    // remove all IDs from a URL
    return urlpath.replace(/\/\d+\//g, '/N/');
  }


  function init() {
    /*
     * Mixpanel client library defined as a global object window.mixpanel
     *
     * from https://mixpanel.com/help/reference/tracking-an-event#install-the-library
     */

    // The token was defined in c3.go()
    mixpanel.init(c3.settings.mixpanelToken);

    // report all clicks on the Help link to Mixpanel, including the current
    // page's path & hash (so we can see what page & tab the user was on when
    // they clicked Help)
    $('a.help-link').on('click', function (e) {
      mixpanel.track("Help clicked", {
        path: cleanurl(window.location.pathname),
        hash: window.location.hash,
        "id.license": window.c3.settings.CBLicenseID,
      });
    });
  }


  c3.mixpanel = {
    init: init
  };
})(window.c3, window.jQuery);
