/*
 * Copied from: static_src/js/c2/jquery.highlight.js
 * Lightweight highlight effect without requiring jQuery UI
 * Derived from http://stackoverflow.com/a/13106698
 */

jQuery.fn.highlight = function (bgColor) {
  $(this).each(function () {
    var el = $(this);
    var offset = el.offset();
    $('<div/>')
      .width(el.outerWidth())
      .height(el.outerHeight())
      .css({
        "position": "absolute",
        "left": offset.left,
        "top": offset.top,
        "background-color": bgColor || "#ffff99",
        "opacity": ".5",
        "z-index": "9999999"
      }).appendTo('body').fadeOut(700).queue(function () {
        $(this).remove();
      });
  });
};
