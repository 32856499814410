/*
 * Functions for Keyboard Shortcuts
 * Copied and modified from: static_src/js/c2/accessibility.js
 */

(function (__module__) {
  'use strict';

  function init() {
    var keyHandlers = {
      '?': openHelpDialog,
      h: openHelpDialog
    };
    keyHandlers = addDocsKeyHandler(keyHandlers);

    c3.commonEventHandlers.registerGlobalKeyHandlers('accessibility', keyHandlers);
  }


  /*
   * Add a global key shortcut for opening docs, but only if the link is currently visible.  Some
   * customers hide this link completely in customer.css for whitelabeling or other reasons.
   *
   * The global help dialog hides this shortcut key using the same logic: if the help link is
   * not visible, that shortcut is simply not shown.
   */
  function addDocsKeyHandler(keyHandlers) {
    var $link = $('[data-topnav="help"] a.help-link');
    if ($link.is(':visible')) {
      keyHandlers.d = function (e) {
        // Open Docs link
        $('#help__docs-link')[0].click();
        e.preventDefault();
      };
    }
    return keyHandlers;
  }


  function openHelpDialog(e) {
    $('#keyboardShortcuts').modal('show');
    e.preventDefault();
  }


  c3[__module__] = {
    init: init
  };
})('accessibility');
