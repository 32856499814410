/*
 * Functions for Forms
 * Copied and modified from: static_src/js/c2/forms.js
 * Revisit the following methods:
 *   [ actionButtonPreview, appendShowAllButton, doOrderAction, enableBatchCheckboxes, 
 *   hide_add_link_when_depleted, iconPreview, makeFormsetManagementData, markRowForDeletionOrNot, 
 *   moveOffsetLabelsToLeftCol, serializeFormAndTableSelection, setupActionFileFields, 
 *   setupSchedulingFields, setupWebHooksForm, splitElementsIntoColumns, updateBatchCheckboxes, 
 *   setUpKubernetesSettingsForm, addTerraformMappingFields, addToDataTableFormDialog, 
 *   initPowerScheduleForm ]
 */

(function (c3, $, _) {
  "use strict";

  /*
   * Toggle Password feature for Password fields
   * Controled via Admin > Misc Settings > Toggle Password
   * When enabled, Password fields should get a show/hide toggle icon
   */
  function initPasswordFields() {
    // Disable existing handlers so we don't end up with multiple
    $(".toggle-password").off("click");
    // Show the password and set a timeout to hide it again
    function show($eye, $input) {
      $eye.toggleClass("fa-eye fa-eye-slash");
      $input.attr("type", "text");
      var timeoutId = setTimeout(function () {
        hide($eye, $input);
      }, $eye.attr("delay"));
      $eye.attr("timeout", timeoutId);
    }
    // Hide the password and clear the timeout if it exists
    function hide($eye, $input) {
      $eye.toggleClass("fa-eye fa-eye-slash");
      $input.attr("type", "password");
      var timeoutId = $eye.attr("timeout");
      clearTimeout(timeoutId);
      $eye.removeAttr("timeout");
    }
    $(".toggle-password").click(function () {
      var $eye = $(this);
      var $input = $("#" + $eye.attr("toggle"));
      if ($input.attr("type") == "password") {
        show($eye, $input);
      } else {
        hide($eye, $input);
      }
    });
  }
  /*
   * Disable buttons for forms on keypress
   * Accounts for some buttons that may be links
   */
  const spinner = c3.spinners.spinner;

  function disableKeypressOnSubmit() {
    // Ignore further keyboard interaction to prevent Enter from causing a CSRF error
    $(document).on("keypress", function (e) {
      e.preventDefault();
      return false;
    });
  }

  function disableBtnOnClick() {
    $(".btn").on("click", function (e) {
      if ($(this).is("a")) {
        $(this).addClass("disabled");
        $(this).html(spinner);
        disableKeypressOnSubmit();
      }
    });
    $("form").on("submit", function (e) {
      $(this).find(":submit").prop("disabled", true);
      $(this).find(":submit").html(spinner);
      disableKeypressOnSubmit();
    });
  }

  c3.forms = {
    initPasswordFields: initPasswordFields,
    disableBtnOnClick: disableBtnOnClick,
    disableKeypressOnSubmit: disableKeypressOnSubmit,
  };
})(window.c3, window.jQuery, window._);
