/*
 * Functions for Bookmarks on navbar
 * Templates: templates/bookmarks/submenu.html
 * View: src/bookmarks/views.py > bookmarks_submenu
 * URL:  src/bookmarks/urls.py > bookmarks_submenu
 * Copied and modified from: static_src/js/c2/bookmarks.js
 */

(function (__module__) {
  'use strict';

  var $topMenuItem;
  var $bookmarks;


  function bookmarkThisPage() {
    var relativeUrlWithHashAndQueryArgs = window.location.href.split(window.location.host)[1];

    $.post(
      $topMenuItem.find('[data-add-bookmark]').attr('href'), {
        title: document.title,
        url: relativeUrlWithHashAndQueryArgs
      },
      function (response) {
        if (response && response.success) {

          c3.include.reload($bookmarks);
          init($topMenuItem);

          $topMenuItem.highlight('#d0e9c6'); // Success green
        } else {
          // Highlight the menu even if the entry was a duplicate, or some
          // error occurred, to acknowledge the keypress.
          $topMenuItem.highlight('#faf2cc'); // Warning yellow
        }
      }
    );
  }


  function goToBookmark(num) {
    var a = $('#bookmarks-submenu a.bookmark')[num - 1];
    if (a && a.href) {
      window.location = a.href;
    } else {
      // Bookmark with this number does not exist
      c3.navbar.closeSubMenu($topMenuItem);
    }
  }


  function init($topMenuItemArg) {
    // Hang on to a jQuery object for the top menu LI
    $topMenuItem = $topMenuItemArg;
    $bookmarks = $topMenuItem.find('#bookmarks-submenu');

    // If submenu content came from memcache, the bookmarks menu never
    // got the data-include attribute.  Set it now to enable reloading.
    $bookmarks.data('include', $bookmarks.data('reload-url'));

    c3.navbar.closeSubMenu($topMenuItem);

    // Remove all event handlers in 'bookmarks' namespace. This allows `init`
    // to be idempotent.  It is called after the submenu is reloaded.
    $topMenuItem.off('.bookmarks');

    c3.commonEventHandlers.registerGlobalKeyHandlers('bookmarks', {
      '.': function (e) {
        bookmarkThisPage();
        e.preventDefault();
      },
      'b': function (e) { // toggle bookmark menu
        if (c3.navbar.isSubMenuOpen($bookmarks)) {
          c3.navbar.closeSubMenu($topMenuItem);
        } else {
          c3.navbar.openSubMenu($topMenuItem);

          // Scroll menu into view
          window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
        e.preventDefault();
      }
    });

    // Click handlers
    $topMenuItem.on('click.bookmarks', '[data-add-bookmark]', function (e) {
      e.preventDefault();
      bookmarkThisPage();
    });

    $topMenuItem.on('click.bookmarks', '[data-delete-bookmark]', function (e) {
      e.preventDefault();
      $.post($(this).attr('href'), {}, function (response) {
        c3.include.reload($bookmarks);
        init($topMenuItem);
      });
    });

    $topMenuItem.on('keydown.bookmarks', function (e) {
      if (c3.navbar.isSubMenuOpen($bookmarks)) {
        var code = e.which || e.keyCode;

        if ((code >= 48 && code <= 57) || (code >= 96 && code <= 105)) {
          // This is a number (1-9 on keyboard or keypad)
          c3.navbar.closeSubMenu($topMenuItem);
          goToBookmark(String.fromCharCode(code));

          // Stop any further global key handlers from running
          e.preventDefault();
        } else if (e.key == 'Escape') {
          c3.navbar.closeSubMenu($topMenuItem);
          e.preventDefault();
        }
      }
    });
  }


  c3[__module__] = {
    init: init
  };
})('bookmarks');
