/*
 * Functions for Login pages found in
 * templates/registration
 * Copied and modified from: static_src/js/c2/login.js
 */

(function (c3) {

  'use strict';

  const hiddenClassName = "d-none";

  function init() {
    if ($('html.ie9').length) {
      $('.not-supported').removeClass(hiddenClassName);
      $('.has-js').addClass(hiddenClassName);
      $('#body-title').addClass(hiddenClassName);
      return;
    } else {
      $('.not-supported').addClass(hiddenClassName);
      $('.has-js').removeClass(hiddenClassName);
      $('#body-title').removeClass(hiddenClassName);
    }

    var $usernameField = $('#id_username');
    $usernameField.focus();

    updateTokenVisibilty();
    $('#id_domain').on('change', updateTokenVisibilty);
  }

  function updateTokenVisibilty() {
    var $token = $('.token');
    if ($('#id_domain').val() === 'CB_LOCAL_USER_STORAGE') {
      $token.addClass(hiddenClassName);
    } else {
      $token.removeClass(hiddenClassName);
    }
  }

  function loggedOut() {
    var minutes = window.location.href.split('inactivity-timeout-minutes=')[1];

    if (minutes != undefined) {
      minutes = minutes.replace(/[!\"#$%&'\(\)\*\+,\.\/:;<=>\?\@\[\\\]\^`\{\|\}~]/g, '');
      $('#timeout').text(minutes);
      $('#inactivity').removeClass(hiddenClassName);
    } else {
      $('#normal-logout').removeClass(hiddenClassName);
      $('#inactivity').addClass(hiddenClassName);
    }
  }


  c3.login = {
    init: init,
    loggedOut: loggedOut
  };

})(window.c3);
