/*
 * Enable Bootstrap Tooltips
 * https://getbootstrap.com/docs/5.2/components/tooltips/#enable-tooltips
 */

(function (c3, $) {
  'use strict';
  function init() {
    $('[data-bs-toggle="tooltip"]').tooltip();
  }


  c3.tooltips = {
    init: init
  };
})(window.c3, window.jQuery);
