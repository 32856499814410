/*
 * Copied from: static_src/js/c2/logging.js
 *
 * Client side logging framework
 *
 * Conditionally logs messages to the console depending on c3.settings.  Lets
 * us write useful permanent log messages into our front end modules that only
 * actually log when configured to do so.
 *
 * Add logging statements to your c3 modules:
 *
 *   var logger = c3.logging.getLogger('server');
 *   logger.debug('this is logged for log level "debug" only');
 *   logger.info('this is logged for log levels "info" and "debug"');
 *   logger.warn('this is logged for log levels "warn" and lower');
 *   logger.error('this should be logged no matter what the log level');
 *
 * Logging for a particular module is enabled by registering it on the c3.settings object in
 * c3.init. For example, to enable the 'c3.include' module:
 *
 *   c3.settings.logging.enabledModules.push('include');
 *
 */

(function (__module__, c3, $) {
  'use strict';

  /*
   * Convert an arguments object into a true array
   */
  function toArray(origArguments) {
    return Array.prototype.slice.call(origArguments, 0);
  }


  /*
   * Returns an object that has various logging methods that log based on logLevel.
   *
   * All messages using this logger include the name of the module that emitted the message, like
   * 'c3.server: '.
   */
  function loggerEnabled(moduleName) {
    var logLevel = c3.settings.logging.level;
    var prefix = moduleName + ': ';

    return {
      // These functions use `apply` to pass arbitrary number of args on to console.log, like
      // unpacking an array in Python via *args.

      // Log messages if the log level is debug
      debug: function () {
        if (logLevel == 'debug') {
          console.log.apply(console, [prefix].concat(toArray(arguments)));
        }
      },

      // Log messages if the log level is info or lower
      info: function () {
        if (logLevel == 'info' || logLevel == 'debug') {
          console.info.apply(console, [prefix].concat(toArray(arguments)));
        }
      },

      // Log warnings if the log level is warn or lower
      warn: function () {
        if (logLevel == 'warn' || logLevel == 'info' || logLevel == 'debug') {
          console.warn.apply(console, [prefix].concat(toArray(arguments)));
        }
      },

      // Always log errors and use console.error() instead
      error: function () {
        console.error.apply(console, [prefix].concat(toArray(arguments)));
      }
    };
  }


  /*
   * Returns an object that has various logging methods that do nothing.
   */
  function loggerDisabled() {
    return {
      debug: function () {},
      info: function () {},
      // Should warnings be logged even when a module isn't enabled?
      warn: function () {},
      // Should errors be logged even when a module isn't enabled?
      error: function () {}
    };
  }


  /*
   * Return a logger object that only logs to console when this module is configured for it.
   * Otherwise, returns a no-op function.
   *
   * Modules are enabled to log messages in c3.init, where the c3.settings.logging object is
   * defined. If enabledModules list contains 'all', logging is enabled for all modules.
   */
  function getLogger(moduleName) {
    if (c3.settings.logging.enabledModules) {
      if (c3.settings.logging.enabledModules.indexOf('all') != -1 ||
        c3.settings.logging.enabledModules.indexOf(moduleName) != -1) {
        return loggerEnabled(moduleName);
      }
    }

    return loggerDisabled();
  }


  c3[__module__] = {
    getLogger: getLogger
  };
})('logging', window.c3, window.jQuery);
