/*
 * Functions for asynchronously loading fragments 
 * by adding a `data-include=url` attribute.
 * Copied and modified from: static_src/js/c2/include.js
 * TODO: [v5] implement clipboard features/functionality and activate clipboard btns
 *
 * Example:
 *
 *    <div data-include="/report-list">
 *      <div class="spinner"></div>
 *    </div>
 *
 * The spinner was added to indicate loading status to the user. When the
 * content is loaded, the spinner will be replaced.
 *
 * Includes are loaded on DOM ready. To load includes that were added later,
 * run `c3.include.load()` or `c3.include.reload()`.
 *
 * data-include-* API:
 *
 *   data-include: URL to fetch from.
 *
 *   data-include-callback: optional function to call once content has loaded, receives 2 args:
 *     el: element having the data-include attribute
 *     success: boolean (true if loaded successfully)
 *
 *   data-include-error: string or false. Message to be inserted into the target
 *     element if an error occurs. Default is "Content failed to load."
 *     If false, no message is shown and the target's original content is left intact.
 *
 *   data-include-refresh: this attribute should be placed on any clickable
 *     element that is to act as a refresh button for this included content. On
 *     click, `reload` will be called on this include.
 *
 *   data-include-timestamp: if this attribute is found on an element in the loaded
 *     content, this element's content is set to the date of the most recent load.
 *
 */

(function (__module__) {
  'use strict';

  const logger = c3.logging.getLogger(__module__);


  function getState(el) {
    return $(el).data('includeState');
  }

  function setState(el, state) {
    $(el).data('includeState', state);
  }

  /* Args:
   * el: jQuery object
   */
  function load(el, block) {
    var content, success;
    var $el = $(el);
    var url = $el.data('include');
    var callback = $el.data('include-callback');
    var errorMsg = $el.data('include-error');

    // don't load if we're already loading or loaded
    if (getState(el)) {
      return;
    }

    setState(el, 'loading');
    if (block || $el.data('block')) {
      c3.spinners.showSpinner({selector:'#content', block:true})
    }

    $.ajax({
      url: url,
      dataType: 'html',
    }).done(function (data) {
      // JavaScript has no way to tell if the response was redirected, as is the case with
      // Maintenance Mode. Since we cannot rely on status code (which is always 200 by the time
      // this handler is called), we resort to string matching.
      if (data && data.indexOf('Maintenance Mode') !== -1) {
        logger.info('Response indicates maintenance mode is on.');
        // Setting success to false here so the target content will not be replaced with a
        // maintenance mode message.
        success = false;
      } else {
        content = data;
        success = true;
      }
    }).fail(function () {
      if (errorMsg === false) {
        // Preserve target's content and fail silently
        return;
      }

      content = errorMsg || 'Content failed to load.';
      success = false;
    }).always(function () {
      if (success === true) {
        // `el.innerHTML = content;` isn't good enough because the browser will
        // not execute any script tags inside of `content`. We use jQuery because
        // it knows to extract the script content, create detached script
        // elements, and append them to the document so that the scripts execute.
        $el.html(content);

        setState(el, 'loaded');

        // Set timestamp if one is desired
        //$el.find('[data-include-timestamp]').text('Last updated ' + moment().format('MMMM Do YYYY, h:mm:ss a'));

        // Find any 'refresh' buttons contained in the new content and set up a click handler
        // to reload this content on click.
        $el.one('click', '[data-include-refresh]', function (e) {
          e.preventDefault();
          c3.include.reload(el, true);
        });

        // Enables any clipboard buttons in the included fragment.
        //c3.clipboard.activateClipboardButtons();
      }

      if (block) {
        c3.spinners.hideSpinner('#content');
      }

      // Notify: trigger an event and invoke callback if defined.
      $el.trigger('cb.include.loaded', success);

      if (callback) {
        callback(el, success);
      }
    });
  }

  function loadAll() {
    $('[data-include]').each(function () {
      load(this);
    });
  }

  // Clear loaded state and load element's content again
  function reload(el, block) {
    setState(el, null);
    load(el, block);
  }


  c3[__module__] = {
    loadAll: loadAll,
    reload: reload,
    getState: getState,
  };

})('include');
