/*
 * Functions for Spinners
 * Hopefully a replacement of static_src/c2/js/block.js
 */

(function (c3) {

  'use strict';

  const hideClassName = "d-none";
  const showClassName = "d-block";
  const spinner = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>';

  function showSpinner({selector = '#spinner-target', block = false, useImage = true} = {}) {
    const spinnerTemplate = document.getElementById('spinner-template')
    const imageTemplate = document.getElementById(useImage ? 'spinner-image':'spinner-circle');
    const spinnerCopy = spinnerTemplate.content.firstElementChild.cloneNode(true);
    const spinnerImageCopy = imageTemplate.content.firstElementChild.cloneNode(true);
    if (block) {
      spinnerCopy.classList.add('vh-100','align-items-center')
    } else if (!useImage) {
      spinnerCopy.classList.add('d-block','mt-3')
    } else {
      spinnerCopy.classList.add(showClassName)
    }
    spinnerCopy.querySelector('#portal-spinner').appendChild(spinnerImageCopy)
    $(selector).html(spinnerCopy)
  }

  function hideSpinner(selector = '#spinner-target') {
    $(selector).empty()
  }

  c3.spinners = {
    hideSpinner: hideSpinner,
    showSpinner: showSpinner,
    spinner: spinner
  };

})(window.c3);
