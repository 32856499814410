/*
 * Functions for Search on navbar
 * Templates: templates/v5/common/navbar/search_menu.html
 *            templates/v5/common/navbar/navbar.html
 *            templates/search/search.html
 * Copied and modified from: static_src/js/c2/search.js
 */

(function (__module__) {
  'use strict';

  // Assigned at the time c3.search.init is called
  var $searchMenu;


  function init() {
    $searchMenu = $('[data-topnav=search]');
    var $searchField = $("#search-input");
    $searchField.clearable();

    // Clear existing search results when the "X" is clicked
    $('#navbar-search').on('click', '.clearable-trigger', function (e) {
      $('#search-results').empty();
    });

    c3.commonEventHandlers.registerGlobalKeyHandlers('search', {
      '/': function (e) {
        if (!c3.navbar.isSubMenuOpen($searchMenu)) {
          openSearchSubMenu();
        } else {
          c2.navbar.closeSubMenu($searchMenu);
          resetSearchSubMenu()
        }
        e.preventDefault();
      }
    });

    // Set up search field key bindings
    $searchField.on('keydown', function (e) {
      var key = e.key || e.keyCode;
      switch (key) {
        case 'ArrowDown':
        case 40: // Move focus to first result
        case 'Down': // MS Edge
          focusResults();
          e.preventDefault();
          break;
      }
    });

    // Hitting enter submits the form
    $('form#navbar-search')
      .off('submit')
      .on('submit', function (e) {
        e.preventDefault();
        queryAndShowResults({
          q: $('#search-input').val()
        });
      });
  }


  function focusResults() {
    setTimeout(function () {
      $('#search-results').find('a').first().focus();
    }, 200);
  }


  function openSearchSubMenu() {
    c3.navbar.openSubMenu($searchMenu);

    // Scroll menu into view
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    autofocusSearchInput();
  }


  function autofocusSearchInput() {
    setTimeout(function () {
      $('#search-input').focus().select();
    }, 200);
  }


  function resetSearchSubMenu() {
    if (c3.navbar.isSubMenuOpen($('[data-topnav=search]').find('ul.dropdown-menu'))) {
      autofocusSearchInput()
    } else {
      $('#search-input').val('')
      $('#search-results').empty();
    }
  }


  function setupSearchResultBehaviors() {
    var $resultsContainer = $('#search-results');
    var key, $from, $to;

    if ($resultsContainer.data('behaviors-initialized')) {
      return;
    }
    $resultsContainer.data('behaviors-initialized', true);

    /**
     * Given a jQuery object of the active search result (.post_result), open its href. If the
     * event object `e` indicates that Cmd or Ctrl is being held down, or it's a middle mouse
     * click, opens in a new tab.
     */
    function goToResult($result, e) {
      var $a = $result.find('a');
      var url = $a.attr('href');

      // Cmd, Ctrl, or middle-click opens in a new tab
      var newTab = (e.metaKey || e.ctrlKey || e.which == 2 || $a.attr('target') == '_blank') ? true : false;

      if (url) {
        if (newTab) {
          window.open(url, '_blank');
        } else {
          window.location = url;
        }
      }
    }


    // Mouse click to go to result
    $searchMenu.on('click', '.post_result', function(e) {
      goToResult($(this), e);
    });


    $searchMenu.on('click', '.search-result-pager a', function (e) {
      e.preventDefault();
      queryAndShowResults({
        q: $(this).data('query'),
        page: $(this).data('page')
      });
    });
  }


  /**
   * Send a GET request to `url` and populate the search results div.
   */
  function queryAndShowResults(data) {
    c3.spinners.showSpinner({selector: '#search-results', useImage:false});
    const searchParams = new URLSearchParams(data)
    const newjqXHR = $.get('/search/?' + searchParams.toString(), function (response) {
      c3.spinners.hideSpinner('#search-results');
      $('#search-results').html(response);
      focusResults();
      c3.navbar.setupKeyNavigationOfMenu($searchMenu, $searchMenu.find('ul.dropdown-menu'));
    });
  }


  c3[__module__] = {
    init: init,
    openSearchSubMenu: openSearchSubMenu,
    setupSearchResultBehaviors: setupSearchResultBehaviors,
    resetSearchSubMenu: resetSearchSubMenu
  };
})('search');
