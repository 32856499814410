/*
 * Functions for common behaviors
 * Copied and modified from: static_src/js/c2/commonEventHandlers.js
 * Revisit the following methods:
 * - enablePostOnClick, acePanelOpen, and popovers
 */

(function (__module__, c3) {
  'use strict';

  var logger = c3.logging.getLogger(__module__);
  var globalKeyHandlerMapping = {};

  /*
   * Lets c3 modules register their own global key handlers (keyup events bound
   * to the document rather than a specific element).
   *
   * Args:
   *   namespace: string must be provided to facilitate management of bound handlers.
   *   keyHandlerMapping: an object whose keys are event.key values (or
   *     event.keyCode for compatibility with some browsers) and whose values
   *     are handler functions.
   *
   * E.g.
   *    registerGlobalKeyHandlers('activityCenter', {'a': toggleActivityCenterSidebar});
   *    registerGlobalKeyHandlers('bookmarks', {
   *      '.': bookmarkThisPage,
   *      'b': toggleBookmarksMenu,
   *      'Escape': closeBookmarksMenuIfOpen,
   *      default: goToBookmarkIfNumeric
   *    });
   *
   * Handlers will only be called if the user is not typing in a form field.
   *
   * A module may register a "default" handler for any keyup events that do not
   * match any of its specific keys. This is context dependent: e.g. bookmarks
   * relies on a default handler to see if a user has typed a number 1-9 and
   * handles that, **but only** if the bookmarks menu is open. Default handlers
   * receive a lot of events, so should always be careful to check the context
   * of the event.
   *
   * Each event handler function gets a single parameter - the event object. If
   * it decides to handle the event, it MUST call event.preventDefault() to
   * prevent any further global keyup handlers from running.
   *
   * E.g.
   * - bookmarks registers a handler for 'Escape' that only fires if the bookmarks menu is open.
   * - search also registers a handler for 'Escape' that only fires if the search menu is open.
   *
   * All handlers should call event.preventDefault() to prevent further global
   * keyup handlers for this key from running.
   */
  function registerGlobalKeyHandlers(namespace, keyHandlerMapping) {
    if (globalKeyHandlerMapping[namespace] === undefined) {
      // First time handlers for this namespace are registered
      globalKeyHandlerMapping[namespace] = keyHandlerMapping;

    } else {
      // Not the first time handlers have been registered for this namespace;
      // add new handlers but only allow one per key.
      for (var key in keyHandlerMapping) {
        globalKeyHandlerMapping[namespace][key] = keyHandlerMapping[key];
      }
    }

    // Need to re-bind using new generated handlerForNamespace function;
    // otherwise the handler for this namespace or another has gone out of
    // scope due to the above changes.
    bindGlobalKeyHandlers();
  }


  /*
   * Unbind and rebind all global registered document-wide keyup event handlers.
   */
  function bindGlobalKeyHandlers() {
    for (var namespace in globalKeyHandlerMapping) {
      var handlerForNamespace = getKeyHandler(namespace);

      // Binding to keyup because it does not repeat like keydown can, triggering excessive events.
      // Prefix with .global namespace to enable adding other keyup handlers on these namespaces.
      $(document).off('keyup.global.' + namespace);
      $(document).on('keyup.global.' + namespace, handlerForNamespace);
    }
  }


  /*
   * Return keyup handler that handles keys for this namespace.
   */
  function getKeyHandler(namespace) {
    return function (e) {
      if (isUserTypingInField(e) || $('.modal.show').length > 0) {
        return;
      }

      var keyHandlers = globalKeyHandlerMapping[namespace];
      // Use the newer `key` if available, otherwise `keyCode` which is deprecated
      var typed = e.key || e.keyCode;
      var foundHandlerForKey = false;

      logger.debug('Look for "' + typed + '" keyup.global.' + namespace + ' handler');

      // Just because a handler is listening for some key does not mean
      // it'll actually handle it.  E.g. A bookmarks handler may only
      // handle 'Escape' if the bookmarks menu is open, while search
      // handler may do the same for the search menu.
      //
      // If a prior handler (in this or another namespace) prevented the
      // default event for this key, using e.preventDefault(), that means
      // it handled it so do not call any further handlers.
      if (e.isDefaultPrevented()) {
        logger.debug('  Event default was already prevented so skipping handlers in this namespace');
        return;
      }
      logger.debug('  active element:', document.activeElement);

      // Loop over list of key-to-handler mappings for this namespace
      for (var key in keyHandlers) {
        if (typed == key) {
          logger.debug('  Found a handler and calling it');
          keyHandlers[key](e);
          foundHandlerForKey = true;

          // Only one handler per key in a namespace
          break;
        }
      }

      // No handlers matched; call the default handler if one is defined.
      if (!e.isDefaultPrevented() && !foundHandlerForKey && keyHandlers.default !== undefined) {
        logger.debug('  Calling default handler in namespace "' + namespace + '"');
        keyHandlers.default(e);
      }
    };
  }


  /*
   * Given a keyup event, return true if the user is typing in certain fields, meaning
   * keystrokes should be ignored by global key event handlers.
   */
  function isUserTypingInField(e) {
    var _focused = document.activeElement;
    var _focusedTagName = _focused.tagName.toLowerCase();

    // Select and selectize dropdowns capture key events before this one, so
    // don't need to be included.
    if (_focusedTagName === "textarea" || _focusedTagName === "input") {
      return true;
    }

    // Other logic?
    return false;
  }


  c3[__module__] = {
    bindGlobalKeyHandlers: bindGlobalKeyHandlers,
    isUserTypingInField: isUserTypingInField,
    registerGlobalKeyHandlers: registerGlobalKeyHandlers
  };
})('commonEventHandlers', window.c3);
