/*
 * Functions for tracking client-side activity
 * Calls a timeout callback after some specified amount of idle time.
 * Copied and modified from: static_src/js/c2/inactivity.js
 *
 * Usage:
 *    c3.inactivity.init(15, function () {
 *        window.location.href = '<logout>';
 *    });
 */

(function (c3, $) {
  'use strict';

  var activityURL;
  var activityPoll;
  var inactivityCallback;
  var timeoutMin;
  var minutesBetweenServerPolls = 1;
  var clientSideTimer;
  var logger = c3.logging.getLogger('inactivity');

  function init(timeoutMinArg, timeoutCallback, activityURLArg) {
    inactivityCallback = timeoutCallback;
    timeoutMin = timeoutMinArg;
    activityURL = activityURLArg;

    // Begin the cycle
    activity();
  }


  function activity() {
    logger.debug('Activity detected.');

    logger.debug('Stop DOM and server-side polling');
    stopObservingDOM();
    clearInterval(activityPoll);

    // Update last-activity time on server so other browser
    // tabs don't deactivate this session unnecessarily.
    logger.debug('POST to server.');
    $.post(activityURL, {}, function (response) {});

    logger.debug('Start polling server and observing DOM in ' + minutesBetweenServerPolls.toString() + ' minutes');
    // Begin observing DOM events again after a while.
    // This prevents them from firing multiple times a second.
    setTimeout(function () {
      startPollingServer();
      startObservingDOM();
    }, minutesBetweenServerPolls * 60000);
  }


  function stopObservingDOM() {
    document.onclick = null;
    document.onkeydown = null;
    document.onmousemove = null;
  }


  function startObservingDOM() {
    // The following DOM events are considered user activity
    document.onclick = activity;
    document.onkeydown = activity;
    document.onmousemove = activity;
  }


  function startPollingServer() {
    // Each timer should poll at a different interval to distribute the
    // requests across a wider timeframe. Add random time up to another minute.
    var intervalMinutes = minutesBetweenServerPolls + Math.random();
    logger.debug('startPollingServer at interval ' + intervalMinutes.toString() + '...');

    activityPoll = setInterval(pollForActivity, intervalMinutes * 60000);
  }


  function pollForActivity() {
    // Determine if the server-side session has also timed out and only call
    // the inactivity callback if so.
    var jqXHR = $.get(activityURL);

    jqXHR.done(function (response) {
      logger.debug('  TimeoutMin: ', timeoutMin);
      logger.debug('  Server poll response: ', response);

      // Stop any client-side timer because that's a fallback for when we
      // cannot reach the server.
      clearTimeout(clientSideTimer);
      clientSideTimer = null;

      if (response.minutesSinceLastActivity > timeoutMin) {
        logger.debug('  ---> Ok.  Time out now.');

        // Shut down DOM observations and server polling
        stopObservingDOM();
        clearInterval(activityPoll);
        inactivityCallback();
      }
    });

    jqXHR.fail(function (jqXHR, textStatus, errorThrown) {
      logger.debug('Poll error: ', textStatus);

      // Perhaps browser is not able to reach server.
      // Start client-side timeout clock as a fallback.
      if (!clientSideTimer) {
        logger.debug('Starting client-side timeout for ' + timeoutMin.toString() + ' minutes...');
        clientSideTimer = setTimeout(inactivityCallback, timeoutMin * 60000);
      }
    });
  }


  c3.inactivity = {
    init: init,
  };
})(window.c3, window.jQuery);
