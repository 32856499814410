/*
 * Functions for hooking into Bootstrap Modals
 * Template: templates/v5/common/elements/modal.html
 *           templates/v5/default/body.html contains {# Global Modals #} <div> containers
 * Copied and modified from: static_src/js/c2/dialogs.js
 *
 * Usage
 * =====
 *
 * For a link to trigger a dialog, it must have:
 *
 *   1. a ``js-dialog-link`` class
 *   2. an ``href`` attribute or data property who's value is a URL that will be loaded into the
 *     modal dialog. The URL should be an HTML fragment that is a
 *     .modal-dialog element. See http://getbootstrap.com/javascript/#modals
 *     for markup.
 *
 * These ``data-`` attributes are supported on the trigger:
 *
 *   * data-dialog-width -- a CSS length for the width of the dialog
 *
 * Default form-submission behavior can be overridden by setting a data
 * attribute, 'customFormSubmissionHandler', on the dialog modal (wait until
 * the dialog is opened)::
 *
 *   $('#dialog-modal').data('customFormSubmissionHandler', function(e) {
 *      alert('Custom form submission process.');
 *   }
 *
 * To trigger the framework's typical form submission behavior, call:
 * c3.dialogs.submitForm
 */

(function (c3) {
  'use strict';

  var dialogSelector = "#dialog-modal"; // a .modal from templates/base.html

  // unlike $form and $submitButton, the dialog modal always exists so we can
  // get it now.
  var $dialog = null;

  // these will be set and re-set after every time a dialog has new content
  // loaded by updateWithJqXHR.
  var $form = null;
  var $submitButton = null;


  function init() {
    $dialog = $(dialogSelector);

    $(document).on('click', '.open-dialog', dialogTriggerClickHandler);
    $(document).on('hidden.bs.modal', handleDialogHidden);
  }


  function handleDialogHidden(e) {
    var modalEl = e.target;

    if (modalEl === $dialog[0]) { // ensure we're looking at the dialogs.js dialog
      // `reload_on_close` dialog setting (see dialog template) allows
      // dialog_views to reload the page, useful if backend data has changed.
      var opts = $dialog.data('dialogOptions');

      // prevent settings from a previous dialog from affecting the next dialog
      $dialog.removeData();

      // clear modal contents so we don't see old content when a new dialog is opened
      $dialog.empty();

      if (opts && opts.reloadOnClose) {
        location.reload();
      }
    }
  }


  function submitFormHandler(e) {
    // a hack to let JS in orders/order_item.html take over.
    var customHandler = $dialog.data('customFormSubmissionHandler');
    if (customHandler) {
      return customHandler(e);
    }

    e.preventDefault();
    submitForm();
  }


  /*
   * For dialogs, form data is derived in one of three possible ways:
   * 1. From data-altPostData attribute on the form element.
   * 2. By calling HTML FormData on the form element, if it's supported.
   * 3. By calling $.serializeArray and skipping file data.
   */
  function serializeDialogForm($form) {
    // Let altPostData override actual form fields
    var data = $form.data('altPostData');
    if (data) {
      return data;
    }

    // When the form has files, we need to serialize using FormData to send it
    // over AJAX. If browser doesn't support FormData (i.e. IE 9) it's not
    // sent.
    var hasFileInputs = $form.find("input[type='file']").length !== 0;
    var supportsFormData = typeof (FormData) !== 'undefined';
    if (hasFileInputs && supportsFormData) {
      return new FormData($form[0]);
    }

    return $form.serializeArray();
  }


  function submitForm() {
    // prevent submitting the dialog multiple times
    if ($dialog.data('submitInProgress')) {
      // Prevent duplicate form submission
      return;
    } else {
      $dialog.data('submitInProgress', true);
    }

    // place the submit button in the loading state to disable it and indicate
    // pending activity.
    var $submitButton = $dialog.find('.modal-footer .btn-primary');
    $submitButton.prop("disabled", true);
    $submitButton.html(c3.spinners.spinner);
    c3.forms.disableKeypressOnSubmit();

    // submit the form
    var opts = $dialog.data('dialogOptions');
    if (opts && opts.useAjax) {
      var settings = {
        data: serializeDialogForm($form),
        type: 'POST',
        url: $form.attr('action')
      };

      if (Object.prototype.toString.call(settings.data) === '[object FormData]') {
        // Prevent jQuery.ajax from automatically transforming data into UTF-8
        settings.processData = false;
        settings.contentType = false;
      }

      var jqXHR = $.ajax(settings);
      updateWithJqXHR(jqXHR);
      jqXHR.always(function () {
        $dialog.data('submitInProgress', false);
      });
    } else {
      var formEl = $form[0];

      if (formEl.target) {
        // Submit is processed in a different window. To avoid the dialog
        // spinning indefinitely, close it.
        $dialog.modal('hide');
      }

      // Call the form /element/'s submit method; calling jQuery's submit
      // method, which triggers a submit event and causes a loop when our
      // custom submit handler is invoked again.
      formEl.submit();
    }
  }

  // Nicely display an error object in a dialog. The error object should have
  // two properties: title, message.
  function displayError(errorObj) {
    var errorHtml =
      '<div class="modal-dialog">' +
      '  <div class="modal-content">' +
      '    <div class="modal-header align-items-start">' +
      '      <h5 class="modal-title text-danger">' + (errorObj.title || 'An Error Has Occurred') + '</h5>' +
      '      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>' +
      '    </div>' +
      '    <div class="modal-body">' +
      '      <code class="text-danger">' +
      (errorObj.message || 'The server is not responding. Please contact an administrator.') +
      (errorObj.details || '') +
      '      </code>' +
      '    </div>' +
      '    <div class="modal-footer">' +
      '      <button class="btn btn-light" data-bs-dismiss="modal">Close</button>' +
      '    </div>' +
      '  </div>' +
      '</div>';
    $dialog.html(errorHtml);
  }

  function updateOnFulfilledAjax(data, textStatus, jqXHR) {
    // the ``dialog_view`` decorator sometimes returns JSON that triggers
    // special behavior. The first two conditionals demonstrate this.
    if (data.redirect_url) {
      // This line is necessary to get rid of the "data unsaved" browser dialog
      window.onbeforeunload = null;
      window.location = data.redirect_url;
    } else if (data.error) {
      displayError(data.error);
    } else if (data.nextURL) {
      // load dialog contents from a new URL
      // console.log('dialog loading next step from data.nextURL: ', data.nextURL);
      var newjqXHR = $.get(data.nextURL);
      displayJqXHR(newjqXHR);
    } else {
      // The data is probably HTML because we haven't been able to
      // understand it as JSON object that the dialog_view decorator
      // returns.
      $dialog.html(data);
    }
  }


  function updateOnRejectedAjax(jqXHR, textStatus, errorThrown) {
    displayError(textStatus + ': ' + errorThrown);
  }

  function updateWithJqXHR(jqXHR) {
    jqXHR.then(
      updateOnFulfilledAjax,
      updateOnRejectedAjax
    ).always(
      function () {
        // The dialog now has new content; we need to re-find the form and submit button.
        $form = $dialog.find('#action_form');
        if ($form.length !== 1) { // the specific selector failed; 'form' is a generic fallback
          $form = $dialog.find('form');
        }
        $submitButton = $dialog.find('.js-submit-form');

        $form.on('submit', submitFormHandler);
        $submitButton.on('click', submitFormHandler);

        // Submit the form when the user presses enter.
        $form.on('keypress', function (e) {
          // did user hit the enter key?
          var enter = e.keyCode === 13;

          // did user hit ctrl or shift while pressing enter?
          var megaEnter = (enter && (e.ctrlKey || e.shiftKey)) || e.keyCode === 10;

          // did the keypress occur while inside a textarea?
          var textarea = e.target.tagName === 'TEXTAREA';

          // submit on enter, except when typing in a textarea (the user wants
          // to insert a line break) unless the user signals intent to submit
          // by also pressing ctrl or shift at the same time.
          if (enter && !textarea || megaEnter) {
            submitFormHandler(e);
          }
        });
      }
    );
  }

  /*
   * When dialog links are clicked, open a dialog and load its content.
   */
  function dialogTriggerClickHandler(e) {
    // If the click originated within an IFRAME, cause the dialog to be opened
    // in the top window instead of the IFRAME. This deprecates the older
    // 'open-parent-dialog' class.
    if (window !== window.top) {
      let crossOriginIframe = false
      try {
        // if we are in a cross origin iframe, this will throw a DOMException
        // as we are not allowed to access objects on window.top
        const test = window.top.c3
      } catch (error) {
        // catch the DOMException error so we can continue,
        // but set the flag to true
        crossOriginIframe = true
      }

      if (!crossOriginIframe) {
        // we are not cross-origin, but we should also check that c3 exists in window.top,
        // e.g., is the parent window a Cloudbolt instance
        if (window.top?.c3) {
          window.top.c3.dialogs.clickHandler(e);
          return;
        }
      }
    }
    e.preventDefault();

    // ``e.currentTarget`` will be the element matched by the second
    // argument to ``.on`` above --- the link element.
    var $dialogToggle = $(e.currentTarget);

    // Bootstrap's modal data-* API supports setting the ``href`` attribute
    // equal to an ID selector, but we haven't implemented support for that
    // here. We assume a remote resource.
    var remote = $dialogToggle.attr('href') || $dialogToggle.data('href');
    // copy data attributes from the trigger to the dialog element so that
    // other bits of code can access dialog settings without needing to know
    // the trigger element. dialog-width is one of these settings.
    $dialog.data($dialogToggle.data());

    var jqXHR = $.get(remote);
    displayJqXHR(jqXHR);
  }


  // Show the dialog, with content loaded from the jqXHR response.
  //
  // Automatically blocks and waits for the response to be fulfilled. Useful
  // for showing dialogs that are the result of a complex $.post with
  // custom-generated request bodies (eg. server batch actions dialog).
  function displayJqXHR(jqXHR) {
    updateWithJqXHR(jqXHR);
    jqXHR.always(function () {});
  }


  // Return True if a dialog is currently open, False otherwise.
  function isOpen() {
    return $('#dialog-modal').hasClass('show');
  }


  // Tell the dialog framework to use different submit behavior: serialize all
  // data in the form as well as all data in selected rows of the dataTable.
  //
  // $table: jQuery object of the table.
  // $actionForm: optional form jQuery object; if not specified, the closest
  //    ancestor form of the dataTable will be used.
  //
  function onSubmitSerializeAllSelectedRows($table, $actionForm) {
    var $form = ($actionForm !== undefined) ? $actionForm : $table.closest('form');
    $table = $($table).dataTable();

    $('#dialog-modal').data('customFormSubmissionHandler', function (e) {
      e.preventDefault();

      // Pass the serialized POST data to the dialog framework
      $form.data('altPostData', c3.forms.serializeFormAndTableSelection($form, $table));

      c3.dialogs.submitForm();
    });
  }


  c3.dialogs = {
    init: init,
    displayJqXHR: displayJqXHR,
    isOpen: isOpen,
    submitForm: submitForm,
    onSubmitSerializeAllSelectedRows: onSubmitSerializeAllSelectedRows,
    clickHandler: dialogTriggerClickHandler
  };
})(window.c3);
