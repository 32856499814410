/*
 * Copied and modified from: static_src/js/c2/jquery.clearable.js
 *
 * jquery.clearable.js
 *
 * jQuery plugin adds a clickable widget to a text input for easy clearing of
 * its value. To clear the input, the user may hit Escape while the input is
 * focused, or click on the clearable trigger icon/span.
 *
 * Options:
 *   inputClass: CSS class applied to the input field.
 *               Default 'clearable'.
 *   triggerHtml: Markup of trigger icon or control.
 *               Default '<i class="fas fa-times"></i>'
 *
 * Usage:
 *
 *     <input name="thing" type="text"/>
 *     <script>
 *       $('input[name=thing]').clearable();
 *     </script>
 *
 * Result:
 *
 *     <input class="clearable" name="thing" type="text"/>
 *     <button class="btn btn-sm btn-light rounded-circle position-relative d-inline-block clearable-trigger">
 *        <i class="fas fa-times"></i>
 *     </button>
 */

(function ($) {
  'use strict';

  $.fn.clearable = function (userOptions) {
    var options = $.extend({}, defaults, userOptions);

    /* Insert the clickable trigger next to the input and hide or show it as
     * needed.
     */
    function addTrigger($input) {
      var $trigger;
      var $parent = $input.parent();
      $input.addClass(options.inputClass);

      // Need input and trigger to be inside a .clearable-wrapper for
      // correct positioning of the trigger. However, Bootstrap input-group
      // layout breaks if the input is not its child. For that special case,
      // wrap the parent of the input instead.
      var wrapper = '<div class="d-flex align-items-center"></div>';
      if ($parent.hasClass('input-group')) {
        $parent.wrap(wrapper);
      } else {
        $input.wrap(wrapper);
      }

      $trigger = $('<button type="button" class="btn btn-sm btn-light rounded-circle position-relative clearable-trigger">' +
        options.triggerHtml + '</button>');
      // place the trigger immediately after the input
      $input.after($trigger);

      hideOrShowTrigger($input);

      // Clear input value and ensure its event handlers fire
      $trigger.click(function (e) {
        clear($input);
      });
    }

    /* Event handler for input element changes */
    function hideOrShowTrigger($input) {
      var $trigger = $input.next();
      if ($input.val()) {
        $trigger.show();
      } else {
        $trigger.hide();
      }
    }

    function clear($input) {
      $input
        .trigger('keydown')
        .val('')
        .trigger('keyup')
        .trigger('change')
        .focus();
    }

    return this.each(function () {
      var $input = $(this);
      if ($input.hasClass(options.inputClass)) {
        // this input is already clearable
        return;
      }

      addTrigger($input);

      $input.keyup(function (e) {
        hideOrShowTrigger($input);

        // Clear the input value when user hits Escape key on the input
        if (e.key == 'Escape') {
          clear($input);
          e.stopPropagation();
        }
      });
    });
  };

  var defaults = {
    inputClass: 'clearable',
    triggerHtml: '<i class="fas fa-times"></i>'
  };

})(jQuery);
