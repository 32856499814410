/*
 * Functions for Content Library
 * Template: templates/content_library/index.html
 */

(function (__module__) {
  'use strict';

  const hideClassName = "d-none";
  const showClassName = "show";

  function init() {
    $("#search_box").change(function () {
      c3.library.setUrlQuery();
    });
    // Allows custom input search when pressing enter
    $("#search_box").submit(function (e) {
      e.preventDefault()
      c3.library.setUrlQuery();
    });
    // Allows custom input search when escaping the search input
    $('#id_searchbar-selectized').on('keydown.searchBindings', function(e) {
      if (e.key == 'Escape') {
        e.preventDefault()
        e.currentTarget.blur()
        if (e.currentTarget.value) {
          c3.library.setUrlQuery()
        } else {
        $("#search_box div.selectize-input").removeClass('focus input-active')
        }
      }
    })
  }

  function setContentType(type) {
    $("#type").val(type);
    c3.library.setUrlQuery();
  }

  function showContent() {
    $("#content-cards").addClass(showClassName).removeClass(hideClassName);
  }

  function setUrlQuery() {
    $("#content-cards").addClass(hideClassName).removeClass(showClassName);
    $("#no-content-cards").addClass(hideClassName).removeClass(showClassName);
    c3.spinners.showSpinner();
    const encodedSort = encodeURI($('#sortBySelect option:selected').val())
    const searchParams = new URLSearchParams(encodedSort);
    searchParams.set("type", encodeURI($("#type").val()));
    // the below variable was discovered by using inspect element on the rendered page
    // turns out that the selectized item is in fact saved as a div element that we can reach
    const searchElement = document.getElementById("search_box");
    const autoCompletedValue = searchElement.getElementsByClassName("item")[0];
    const userInputValue = $("#id_searchbar-selectized")[0];
    let searchTerm
    if (autoCompletedValue && autoCompletedValue?.dataset?.value) {
      searchTerm = `${autoCompletedValue.dataset.value}`
    }
    if (userInputValue && userInputValue?.value) {
      searchTerm = `${userInputValue.value}`
    }
    if (searchTerm != '' && searchTerm != undefined) {
      searchParams.set("search", `${searchTerm}`);
    }
    window.location = `?${searchParams.toString()}`;
  }

  c3[__module__] = {
    init: init,
    setUrlQuery: setUrlQuery,
    setContentType: setContentType,
    showContent: showContent
  };
})('library');
