/*
 * Copied from: static_src/js/c2/selectize.js
 */

(function (__module__) {
  'use strict';

  var logger = c3.logging.getLogger(__module__);

  /**
   * This is essentially just a thin wrapper around selectize.
   * Apply Selectize to the selector, using global default settings.
   * The optional `userSettings` object overrides defaults.
   */
  c3.selectize = function (selector, userSettings) {
    var defaults = {
      // Insert the dropdown into the DOM inside body instead of as a sibling of
      // the original control, to avoid dropdown being covered by bottom of
      // tables, the page footer, etc.
      dropdownParent: 'body',
      plugins: {
        // Inserts an option for the empty choice, if there is one
        // note: this seems no longer to be necessary, and can result in a duplicate entry
        // selectable_placeholder: {}
      },
      selectOnTab: true,
      // sort alphabetically by label field (named 'text' by default)
      sortField: 'text'
    };
    var settings = $.extend({}, defaults, userSettings || {});
    // Remove sortField from settings when userSettings passes in optional key 'sortField'
    // and sortField=null to honor src > common > fields.py
    if (userSettings && "sortField" in userSettings && userSettings.sortField == null) {
      delete settings.sortField
    };
    var $target = $(selector);
    logger.info('selectize settings for', selector + ':', settings);

    if ($target.length === 0) {
      logger.info('c3.selectize did not find a target at $("' + selector + '")');
      return;
    }

    if ($target.closest('.modal-dialog').length) {
      // Dropdowns in dialogs must be inserted alongside the original; otherwise
      // they appear below the modal.
      settings.dropdownParent = null;
    }

    if (settings.sortNumerically) {
      // It would make sense to set sortField to 'value', but that
      // doesn't work. Setting sortField to null allows
      // ints and decimals to maintain the order they were sorted in from the backend.
      settings.sortField = null;
    }

    if (settings.suggest_options_field_name) {
      var $wrap = $target.closest('.controls');
      if ($wrap.length === 0) {
        // Not on a form that wraps fields the standard way (like the order form). This
        // is the case on 'Add Parameter to Server' dialog. In this case, just use the
        // parent of the selectized element.
        $wrap = $target.parent();
      }

      // This widget will auto-suggest options as user types, using Selectize's `load` option.
      // Data source is a CB view that runs a generate parameters action associated with this
      // field name. The action must contain a special function `suggest_options` that takes
      // the field and current query string.
      settings.load = function (query, callback) {
        if (!query.length) {
          return callback();
        }
        var $selectized = $wrap.find('.selectized');
        var url = '/suggest_options_for_selectize/' + settings.suggest_options_field_name + '/';

        logger.debug('Loading options for selectize with query:', query);

        $.get(url, {
          query: query
        }, function (result) {
          if (result.options) {
            // Fix issue on first open where the dropdown is too narrow, like 90px.
            // Set the width to match the selectize form field.
            var $dropdown = $selectized[0].selectize.$dropdown;
            logger.debug('  $dropdown orig width:', $dropdown.css('width'));
            $dropdown.css('min-width', '600px');
            callback(result.options);
          } else {
            console.error(result);
            callback([{
              value: '',
              text: 'An error occurred while looking up options'
            }]);
          }
        });
      };

      // Prevent excessive requests while user is typing, but not delay so much that it's disruptive
      settings.loadThrottle = 400;
    }

    $target.selectize(settings);
    return $target[0].selectize;
  };


  /**
   * Client side equivalent to our SelectizeMultiple Django form widget.
   */
  c3.selectizeMultiple = function (selector, userSettings) {
    var defaults = {
      placeholder: 'Choose one or more...',
      plugins: ['remove_button', 'batch_toolbar']
    };

    return c3.selectize(selector, $.extend(defaults, userSettings || {}));
  };

})('selectize');
