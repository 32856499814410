/*
 * Copied and modified from: static_src/js/c2/spotlight.js
 */
/* spotlight.js - Local page search with in-place highlighting.
 *
 * As user types, matching text on the page is highlighted in place. The search
 * data set is composed of all visible text nodes contained by elements having
 * attribute data-spotlight. Additionally, synonyms and related search terms
 * may be provided on those elements by the value data-spotlight attribute.
 *
 * Search engine is the same as used by Selectize, sifter.js.
 * At call time, builds a sifter hash datastructure of all selected text nodes.
 * https://github.com/brianreavis/sifter.js#sifterjs
 *
 *
 * Usage:
 *
 *   <div class="spotlight-search-bar">
 *     <input data-spotlight-search="#searchSpaceContainer" type="text" ...>
 *     <div class="spotlight-results">
 *       <ol class="list-unstyled"></ol>
 *     </div>
 *   </div>
 *
 *   <p data-spotlight>Some searchable text</p>
 *   <a href="" data-spotlight="canine puppy">Dog</a>
 *   <a href="" data-spotlight="feline kitty">Cat</a>
 *
 *   <script>c3.spotlight.init();</script>
 *
 * In the above example, searching for...
 *   some   - highlights the entire paragraph
 *   puppy  - highlights the Dog link
 *   at     - highlights the Cat link
 *
 * Highlight style is customized by the .spotlight CSS class which is added to
 * any highlighted elements (the same ones that have the data-spotlight
 * attribute.
 *
 * data-spotlight-search may also be given a value to specify an element
 * selector to apply when associating data-spotlight elements to that input. In
 * this way, multiple independent spotlight searches can exist on the same page
 * (e.g. in different tabs).
 *
 *   <input data-spotlight-search="#tab1" ...>
 *   <input data-spotlight-search=".column2" ...>
 *
 */

(function (__module__) {
  'use strict';

  const logger = c3.logging.getLogger(__module__);

  function init() {
    const $input = $('input[data-spotlight-search]');
    $input.each(initOneSearchField);
  }

  /* Set up a spotlight search field and its target content.
   */
  function initOneSearchField() {
    /*jshint validthis: true */
    const $input = $(this);
    logger.debug('Initializing a spotlight search field:', $input);
    const $thisSearchBar = $input.closest('.spotlight-search-bar');
    const $dropdown = $thisSearchBar.find('.spotlight-results');
    const items = getAllSpotlightItems($thisSearchBar);
    logger.debug('  Spotlightable items:', items);
    // Build the datastructure used by sifter
    const data = _.map(items, function (item) {
          const $item = $(item);
          return {
            'text': $item.text(),
            'keywords': $item.data('spotlight') || "",
          };
        });
    logger.debug('  sifter search engine data:', data);
    const sifter = new Sifter(data);

    $input.on('keydown.spotlight', function(e) {
      logger.debug('keydown event on spotlight search field:', e);
      switch (e.key) {
        case 'ArrowDown':
        case 'Down': // MS Edge
        case 'Tab':
          $dropdown.find('.result').first().focus();
          e.preventDefault();
          return;
      }

      // Gotcha: the new input value is not yet available to keydown event handlers (which enables
      // them to cancel the event if desired). Thus, we must use setTimeout or $.debounce to
      // essentially allow the event to proceed and the browser to update the input before using
      // it.
      setTimeout(function() {
        handleKeydownOnInput(e, items, sifter, $thisSearchBar, $dropdown);
      }, 1);
    });
    $input.keyup(_.debounce(function() {
      if ($dropdown.find('span.fas').length > 0) {
        c3.tooltips.init()
      }
    }, 400));

  }


  /*
   * Key events on the search input field.
   */
  function handleKeydownOnInput(e, items, sifter, $thisSearchBar, $dropdown) {
    const query = $(e.target).val();
    if (query === "") {
      clearResults($dropdown);
      return;
    }

    const results = sifter.search(query, {fields: ['text', 'keywords']});
    const matches = _.at(items, _.map(results.items, 'id'));
    unspotlight(items);
    spotlight(matches);
    showResults($dropdown, matches);
  }


  /*
   * Highlight matching items on the page.
   */
  function spotlight(items) {
    $(items).each(function() {
      const $item = $(this);
      $item.addClass('spotlight');

      const $container = $item.closest('.collapse');
      // If the item being spotlighted is inside a
      // closed collapsible, open it.
      if (!$container.hasClass('show')) {
        $container.collapse('show');
      }
    });
  }


  /*
   * Remove highlight from matching items on the page.
   */
  function unspotlight(items) {
    $(items).removeClass('spotlight');
  }


  /*
   * Show a dropdown containing all matching items and set up behaviors for click and keyboard.
   */
  function showResults($dropdown, results) {
    const $list = $dropdown.find('ul');
    $list.html('');

    if (results.length === 0) {
      $list.append($("<li class='list-group-item'><i>No matches on page.</i></li>"));
      return;
    }

    // Clone all result elements and add them to the list
    $(results).each(function() {
      addResultToList($list, $(this));
    });
    setupSpotlightResultsBehaviors($dropdown);
    $dropdown.addClass('active');
  }


  function clearResults($dropdown) {
    $dropdown.removeClass('active');
    const $list = $dropdown.find('ul');
    const $searchBar = $dropdown.closest('.spotlight-search-bar');
    const items = getAllSpotlightItems($searchBar);
    unspotlight(items);
    $list.html('');
    $searchBar.find('input[data-spotlight-search]').val('').focus();
  }


  /*
   * Given a spotlight search bar, find all targets on the page which it searches and return them,
   * but as a list of DOM nodes not jQuery objects.
   */
  function getAllSpotlightItems($searchBar) {
    const $input = $searchBar.find('input[data-spotlight-search]');
    // If no selector is specified, search the entire body.
    const containerSelector = $input.data('spotlight-search') || 'body';
    return document.querySelectorAll(containerSelector + ' [data-spotlight]');
  }


  /*
   * Append search result to results list, wrapped in a LI.
   *
   * If the result is an anchor, clone it and strip its spotlight attrs.  If result is not an
   * anchor, such as a section heading, repeat this for all anchors contained in it.
   */
  function addResultToList($list, $result) {
    if ($result[0].tagName == 'A') {
      $list.append(cloneResultLink($result));
    } else {
      // Disabled "Special case: Bootstrap collapsible" which added all 
      // the links under Admin Extensions when "admin" was searched. 
      // if ($result.data('bsToggle') == 'collapse') {
      //   const $accordionContent = $(`#${$result.data('test')}`);
      //   $accordionContent.find('a[data-spotlight]').each(function() {
      //     addResultToList($list, $(this));
      //   });
      // }

      // Special case: List items with disabled content and tooltips
      if ($result[0].tagName == 'LI' && $result[0].className.includes('list-group-item inactive')) {
        const clonedLink = cloneResultLink($result)
        // Add "result" class to the span instead of the <li> or disabled <a>, enable tab selection
        clonedLink[0].classList.remove('result')
        clonedLink[0].children[1].classList.add('result')
        clonedLink[0].children[1].tabIndex = 0
        $list.append(clonedLink)
      } 
    }
  }


  /*
   * Return a clone of an anchor represented by the given jQuery obj.
   * Remove attrs and classes that aren't needed in the results list.
   */
  function cloneResultLink($result) {
    const $link = $result.clone();
    $link.removeClass('spotlight');
    $link.removeAttr('data-spotlight');
    $link.addClass('result')
    return $link;
  }


  /*
   * Set up mouse and keyboard event handlers for navigating search results and closing the
   * dropdown.
   */
  function setupSpotlightResultsBehaviors($dropdown) {
    if ($dropdown.data('behaviors-initialized')) {
      return;
    }
    $dropdown.data('behaviors-initialized', true);

    // Close dropdown when user clicks outside of it
    $(document).on('click', function(e) {
      if (!$(e.target).closest('.spotlight-results').length) {
        $dropdown.removeClass('active');
      }
    });

    // Keyboard navigation
    $(document).on('keydown.spotlight', '.spotlight-results', function(e) {
      if (e.key.metaKey || e.key.ctrlKey) {
        return;
      }

      const $dropdown = $(this),
          $items = $dropdown.find('.result'),
          focused = document.activeElement,
          focusedIndex = $items.index(focused);
      
      switch (e.key) {
        case 'ArrowDown':
        case 'Down': // MS Edge
          if (focusedIndex < $items.length - 1) {
            // Move focus to next result
            $items.eq(focusedIndex + 1).focus();
          }
          // Also prevent scrolling of viewport
          e.preventDefault();
          break;
        case 'ArrowUp':
        case 'Up': // MS Edge
          if (focusedIndex === 0) {
            // Focus the input
            $(this).closest('.spotlight-search-bar').find('input').select();
          } else {
            // Move focus to previous result
            $items.eq(focusedIndex - 1).focus();
          }
          e.preventDefault();
          break;
        case 'Escape':
        case 'Esc': // MS Edge
          clearResults($dropdown);
          e.preventDefault();
          break;
      }
    });
  }


  c3[__module__] = {
    init: init
  };

})('spotlight');
